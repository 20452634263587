/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React from 'react';
import Plx from 'react-plx';
import { Grid, Wrapper, CardText } from '../../components';
import styles from './Sophrology.module.scss';
// import Flowa2 from '../../assets/svg/flowa1.svg';
import Flowa1 from '../../assets/svg/group9.svg';
import Flowa4 from '../../assets/svg/flowa5.svg';
import Flowa7 from '../../assets/svg/flowa6.svg';
import Flowa3 from '../../assets/svg/flowa2.svg';

import Title from '../../assets/svg/title3.svg';

// eslint-disable-next-line arrow-body-style
const Sophrology = () => {
  return (
    <Wrapper>
      <div id="Sophrology" className={styles.container}>
        <section className={styles.section}>
          <Grid classes={[styles.gridContainer, styles.gridAjust].join(' ')}>
            <div className={styles.titleContainer}>
              <img src={Title} alt="rr" className={styles.Title} />
            </div>
            <div className={styles.flowerContainer1}>
              <Plx
                className={styles.flower2}
                parallaxData={[
                  {
                    start: '#Sophrology',
                    duration: 2000,
                    properties: [
                      {
                        startValue: -100,
                        endValue: 100,
                        property: 'translateY',
                      },
                    ],
                  },
                ]}
              >
                <img src={Flowa1} alt="rr" className={styles.flower1} />
              </Plx>
            </div>
            <div className={styles.flowerContainer2}>
              <Plx
                className={styles.flower1}
                parallaxData={[
                  {
                    start: '#Sophrology',
                    duration: 2000,
                    properties: [
                      {
                        startValue: 200,
                        endValue: -200,
                        property: 'translateY',
                      },
                    ],
                  },
                ]}
              >
                <img src={Flowa3} alt="rr" />
              </Plx>
              {/* <img src={Flowa7} alt="rr" className={styles.flower1} /> */}

              <Plx
                className={styles.flower2}
                parallaxData={[
                  {
                    start: '#Sophrology',
                    duration: 2000,
                    properties: [
                      {
                        startValue: 400,
                        endValue: -400,
                        property: 'translateY',
                      },
                    ],
                  },
                ]}
              >
                <img src={Flowa7} alt="rr" className={styles.flower2} />
              </Plx>
            </div>
            {/* <img src={Flowa1} alt="rr" className={styles.Flowa1} /> */}
            <CardText classes={[styles.cardAjust, styles.cardAjust1].join(' ')}>
              La sophrologie est une méthode psycho corporelle créée en 1960, par le neuropsychiatre
              Alfonso Caycedo qui a pour objectif de nous aider à retrouver
              l&apos;équilibre physique, psychique et émotionnel, indispensable à notre
              bien être. Elle s&apos;inspire de pratiques
              orientales ( méditation, zen, yoga) et occidentales
              (hypnose, relaxation, pensée positive).
              {' '}
              <br />
              <br />
              {' '}
              Elle est fondée sur 3 grands principes:
              {' '}
              <br />
              {' '}
              * La prise de conscience de son schéma corporel
              {' '}
              <br />
              {' '}
              * Le principe d&apos;action positive
              {' '}
              <br />
              {' '}
              * Le principe de la réalité objective (voir les choses telles q&apos;elles
              sont réellement et non comme nous les avons imaginées et interprétées).
              {' '}
              <br />

            </CardText>
            <CardText classes={[styles.cardAjust, styles.cardAjust2].join(' ')}>
              La sophrologie peut vous aider et je peux vous accompagner pour :
              {' '}
              <br />
              <br />
              {' '}
              * Le stress, l&apos;anxiété, les crises d&apos;angoisse, burn out
              {' '}
              <br />
              {' '}
              * Comprendre et mieux gérer vos émotions
              {' '}
              <br />
              {' '}
              * Les troubles du sommeil
              {' '}
              <br />
              {' '}
              * Les troubles de la concentration
              {' '}
              <br />
              {' '}
              * Les douleurs chroniques
              {' '}
              <br />
              {' '}
              * L&apos;accompagnement à la périnatalité
              {' '}
              <br />
              {' '}
              * La préparation à un examen (médical, professionnel, scolaire,..)
              {' '}
              <br />
              {' '}
              Vous avez besoin d'une thérapie complémentaire pour vous aider
              à gérer vos émotions, votre douleur et vous reconnectez à votre corps...
              {' '}
              <br />
              <br />
              {' '}
              Cette méthode holistique s&apos;adapte au petit comme
              au grand ayant l&apos;envie d&apos;aller chercher
              les trésors qui se trouvent en lui pour
              remettre du bien être et de la magie dans son quotidien.
              {' '}
              <br />
              {' '}
              Elle ne remplace en aucun cas une consultation avec un psychologue ou un médecin,
              ni un traitement médical préscrit.
            </CardText>
            <CardText classes={[styles.cardAjust, styles.cardAjust4].join(' ')}>
              <blockquote>
                "Chacunes de vos pensées, de vos paroles, de vos actions ont une
                influence sur vous."
              </blockquote>
            </CardText>
            <Plx
              className={styles.Flowa3}
              parallaxData={[
                {
                  start: '#Sophrology',
                  duration: 2000,
                  properties: [
                    {
                      startValue: 400,
                      endValue: -400,
                      property: 'translateY',
                    },
                  ],
                },
              ]}
            >
              <img src={Flowa4} alt="rr" />
            </Plx>
          </Grid>

        </section>
      </div>
    </Wrapper>
  );
};

export default Sophrology;
