/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import Plx from 'react-plx';
import {
  Grid, Wrapper, CardText,
} from '../../components';
import styles from './About.module.scss';
import Flowa2 from '../../assets/svg/flowa1.svg';
import Flowa5 from '../../assets/svg/group10.svg';
import Flowa6 from '../../assets/svg/flowa6.svg';
import Flowa8 from '../../assets/svg/flowa8.svg';

const About = () => {
  return (
    <Wrapper>
      <div className={styles.container}>
        <section id="about" className={styles.section}>
          <Grid classes={[styles.gridContainer, styles.gridAjust].join(' ')}>
            <div className={styles.cardContainer}>
              <Plx
                classes={styles.cardAjust}
                parallaxData={[
                  {
                    start: '#about',
                    duration: 1000,
                    properties: [
                      {
                        startValue: 100,
                        endValue: -100,
                        property: 'translateY',
                      },
                    ],
                  },
                ]}
              >
                <CardText>
                  Je vous invite à visiter les différentes rubriques
                  de mon site afin de vous accorder le temps de
                  quelques pages. Une bulle dans votre quotidien
                  dans laquelle je vous présente
                  mon activité de sophrologue et les différents champs
                  d’applications de mes pratiques,
                  mon parcours ou encore le déroulement d’une séance.
                  Je serai ravie de prendre contact avec vous, afin
                  d’échanger et de vous accompagner sur le chemin
                  de votre bien être.
                </CardText>
              </Plx>
            </div>
            <CardText classes={[styles.cardAjustYellow].join(' ')}>
              <blockquote>
                "Le bonheur n'est pas au sommet de la montagne mais dans la façon de la gravir."
              </blockquote>
            </CardText>
            <div className={styles.Title1Container} />
            <div className={styles.Flowa5Container}>
              <img src={Flowa5} alt="rr" className={styles.Flowa5} />
            </div>
            <div className={styles.Flowa6Container}>
              <img src={Flowa6} alt="rr" className={styles.Flowa6} />
            </div>
            <div className={styles.Flowa8Container}>
              <img src={Flowa2} alt="rr" className={styles.Flowa7} />
              <img src={Flowa6} alt="rr" className={styles.Flowa8} />
              <img src={Flowa6} alt="rr" className={styles.Flowa9} />
              <img src={Flowa8} alt="rr" className={styles.Flowa10} />
              <img src={Flowa2} alt="rr" className={styles.Flowa11} />
            </div>
          </Grid>
        </section>
      </div>
    </Wrapper>
  );
};

export default About;
