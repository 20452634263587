/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, animateScroll as scroll } from 'react-scroll';
import { menuNavMain } from '../../wording/navigation';

const Navigation = ({
  classes,
  handleClick,
  style,
  navRef,
}) => (
  <nav
    ref={navRef}
    className={classes}
    style={{ ...style }}
  >
    <ul>
      {menuNavMain.map((item, index) => (
        <li
          key={item.id}
        >
          <Link
            onClick={() => handleClick()}
            activeClass="active"
            to={item.id}
            spy
            smooth
            offset={0}
            duration={700}
          >
            {item.linkName}
          </Link>
          <span />
        </li>
      ))}
    </ul>
  </nav>
);

Navigation.defaultProps = {
  navRef: React.createRef(),
  style: {},
  classes: '',
  handleClick: () => {},
};
Navigation.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  classes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  navRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ]),

  handleClick: PropTypes.func,
};

export default Navigation;
