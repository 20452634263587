import React from 'react';
import PropTypes from 'prop-types';
import styles from './SvgWrapper.module.scss';

const SvgWrapper = React.memo(({
  svg, style, logoHeaderRef, classes,
// eslint-disable-next-line arrow-body-style
}) => {
  return (
    <div
      ref={logoHeaderRef}
      className={[styles.container, classes].join(' ')}
      style={{ ...style }}
    >
      {svg}
    </div>
  );
});

SvgWrapper.defaultProps = {
  svg: [],
  logoHeaderRef: React.createRef(),
  style: {},
  classes: '',
};

SvgWrapper.propTypes = {
  logoHeaderRef: PropTypes.shape([PropTypes.object]),
  svg: PropTypes.shape([PropTypes.node]),
  style: PropTypes.shape([PropTypes.number, PropTypes.object, PropTypes.string]),
  classes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default SvgWrapper;
