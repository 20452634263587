/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import Plx from 'react-plx';
import { Grid, Wrapper, CardText } from '../../components';
import styles from './MyWay.module.scss';
import Flowa1 from '../../assets/svg/flowa4.svg';
import Flowa2 from '../../assets/svg/flowa1.svg';
import Flowa3 from '../../assets/svg/flowa5.svg';
import Flowa4 from '../../assets/svg/groupofflowa1.svg';
import Flowa5 from '../../assets/svg/flowa6.svg';
import Title from '../../assets/svg/title2.svg';

const MyWay = () => {
  return (
    <Wrapper>
      <div className={styles.container}>
        <section id="myWay" className={styles.section}>
          <Grid classes={[styles.gridContainer, styles.gridAjust].join(' ')}>
            <div className={styles.titleContainer}>
              <img src={Title} alt="rr" className={styles.Title} />
            </div>
            <img src={Flowa1} alt="rr" className={styles.Flowa1} />
            <img src={Flowa4} alt="rr" className={styles.Flowa5} />
            <img src={Flowa2} alt="rr" className={styles.Flowa2} />

            <CardText classes={[styles.cardAjust, styles.cardAjust1].join(' ')}>
              <img src={Flowa2} alt="rr" className={styles.Flowa2b} />
              Je m&apos;appelle Fanny, je suis maman, sophrologue, aide soignante et tout plein d&apos;autres choses, ...
              Je travaille depuis 20 ans à l&apos;hôpital, où je prends soin de mes patients.
              J&apos;écoute leurs peurs, leurs colères, leurs souffrances, leurs joies, leurs rires.
              J&apos;apaise leurs douleurs avec le toucher, des mots doux et des sourires.
              Au fil du temps, ces petits gestes qui me semblaient naturels sont devenus des remèdes magiques
              afin d&apos;apaiser leurs émotions.
            </CardText>
            <div className={[styles.cardAjust, styles.cardAjust2].join(' ')}>
              <Plx
                classes={styles.cardAjust}
                parallaxData={[
                  {
                    start: '#myWay',
                    duration: 1500,
                    properties: [
                      {
                        startValue: 200,
                        endValue: -70,
                        property: 'translateY',
                      },
                    ],
                  },
                ]}
              >
                <CardText>
                  Depuis quelques années, ne me sentant plus alignée avec certaines de mes valeurs, et malgré les nombreux messages d'alerte que m&apos;envoyait mon corps, je me suis épuisée.
                  <img src={Flowa2} alt="rr" className={styles.Flowa3} />
                </CardText>
              </Plx>
            </div>
            <CardText classes={[styles.cardAjust, styles.cardAjust3].join(' ')}>
              Je me suis alors passionnée pour des pratiques naturelles comme le yoga, l&apos;hypnose,
              l&apos;aromathérapie et l&apos;ayurveda.
              La sophrologie est entrée dans ma vie, au moment ou j&apos;avais un besoin immense de me reconnecter
              avec moi même, avec mes valeurs, avec la Terre.
              Chaque jour, je m&apos;inspire, intègre, pratique, partage et transmet à mon propre rythme,
              cette méthode qui est avant tout une philosophie de vie et je réalise que mes sourires,
              mes mots doux,
              mon intuition et ma grande sensibilité m&apos;offrent en réalité le pouvoir magique d&apos;apaiser vos émotions.
              {' '}
              <br />
              <br />
              {' '}
              Mes formations:
              {' '}
              <br />
              {' '}
              - Formation d&apos;aide soignante à l&apos;hôpital St Joseph St Luc en 2002
              {' '}
              <br />
              {' '}
              - Aide soignante en service de gériatrie, médecines, chirurgies aux hospices civils de Lyon depuis 2003.
              {' '}
              <br />
              {' '}
              - Formation de sophrologie certifiée à sofrocay en 2021.
              <br />
              {' '}
              - Formation spécialisation sophro relaxation en 2022.
              <img src={Flowa3} alt="rr" className={styles.Flowa4} />
            </CardText>
            <CardText classes={[styles.cardAjust, styles.cardAjust4].join(' ')}>
              <blockquote>
                "En faisant scintiller notre lumière, nous offrons aux autres la possibilité d'en faire autant."
              </blockquote>
            </CardText>
            <img src={Flowa5} alt="rr" className={styles.Flowa6} />

          </Grid>

        </section>
      </div>
    </Wrapper>
  );
};

export default MyWay;
