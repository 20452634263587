import React from 'react';
import PropTypes from 'prop-types';
import styles from './Grid.module.scss';

const Grid = React.memo(({ children, classes }) => (
  <div className={[styles['grid-container'], classes].join(' ')}>
    {children}
  </div>
));

Grid.defaultProps = {
  classes: '',
};

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.string,
};

export default Grid;
