/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import axios from 'axios';
// import Recaptcha from 'react-recaptcha';
import Plx from 'react-plx';
import { Wrapper, Grid, CardText } from '../../components';
import styles from './Contact.module.scss';
import Flowa1 from '../../assets/svg/flowa1.svg';
import Flowa2 from '../../assets/svg/groupofflowa3.svg';

import Title from '../../assets/svg/title5.svg';

const Contact = () => {
  const [state, setState] = useState({
    firstname: '',
    lastname: '',
    age: 0,
    email: '',
    subject: '',
  });

  // const [verify, setVerify] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    axios({
      method: 'POST',
      url: '/fannyperez-php-mail/api/demo.php',
      headers: { 'content-type': 'application/json' },
      data: state,
    })
      .then((result) => {
        setState(result.data);
      })
      .catch((error) => console.log('error', error));
    // if (verify) {
    // } else {
    //   alert('Verify that you are you are a human');
    // }
  };

  // const callback = () => {
  //   setVerify(true);
  // };

  return (
    <Wrapper>
      <div id="contact" className={styles.container}>
        <section className={styles.section}>
          <Grid classes={[styles.gridContainer, styles.gridAjust].join(' ')}>
            <div className={styles.titleContainer}>
              <img src={Title} alt="rr" className={styles.Title} />
            </div>
            <div className={styles.flowerContainer1}>
              <img src={Flowa1} alt="rr" className={styles.flower1} />
            </div>
            <div className={styles.flowerContainer2}>
              <img src={Flowa2} alt="rr" className={styles.flower1} />
            </div>
            <CardText classes={[styles.cardAjust, styles.cardAjustFirst].join(' ')}>
              Je suis disponible uniquement sur rendez-vous.
              Afin d’établir un premier contact, vous avez la possibilité de remplir ce formulaire
              afin de me communiquer vos coordonnées et me présenter vos souhaits en quelques lignes...
            </CardText>

            <Plx
              className={[styles.cardAjust, styles.cardAjustAddress].join(' ')}
              parallaxData={[
                {
                  start: '#contact',
                  duration: 2000,
                  properties: [
                    {
                      startValue: 200,
                      endValue: -200,
                      property: 'translateY',
                    },
                  ],
                },
              ]}
            >
              <CardText classes={styles.customCard}>
                <h4>Adresse</h4>
                <p>Centre Apogée Hénon</p>
                <p>69 boulevard des Canuts 69004 LYON</p>
              </CardText>
            </Plx>
            <CardText classes={[styles.cardAjustContact].join(' ')}>
              <form method="POST" action="/action_page.php">
                <h2>Entrons en contact</h2>
                <div className={styles['inputs-container']}>
                  <div>
                    <label htmlFor="firstname">First Name</label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      placeholder="Nom :"
                      onChange={(event) => {
                        setState({ ...state, firstname: event.currentTarget.value });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      placeholder="Prénom :"
                      onChange={(event) => {
                        setState({ ...state, lastname: event.currentTarget.value });
                      }}
                    />
                  </div>
                </div>
                <div className={styles['inputs-container']}>
                  <div>
                    <label htmlFor="age">Age</label>
                    <input
                      type="number"
                      min="0"
                      max="120"
                      id="age"
                      name="age"
                      placeholder="Age : "
                      onChange={(event) => {
                        setState({ ...state, age: event.currentTarget.value });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Mail :"
                      onChange={(event) => {
                        setState({ ...state, email: event.currentTarget.value });
                      }}
                    />
                  </div>
                </div>
                <div className={[styles['inputs-container'], styles.submit].join(' ')}>
                  <div>
                    <label htmlFor="subject">Subject</label>
                    <textarea
                      id="subject"
                      name="subject"
                      placeholder="Nature de votre demande :"
                      rows="5"
                      onChange={(event) => {
                        setState({ ...state, subject: event.currentTarget.value });
                      }}
                    />
                  </div>
                  <div className={styles['submit-container']}>
                    <input type="submit" value="envoyer" onClick={handleFormSubmit} />
                    <span />
                  </div>
                </div>
                {state.mailSent ? (
                  <div style={{ textAlign: 'center', marginBottom: '20px' }}>{state.messageStatut}</div>
                ) : (
                  <div style={{
                    width: '70%', overflow: 'hidden', position: 'relative',
                  }}
                  >
                    {/* <Recaptcha
                      className={styles['reCatpcha-container']}
                      sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      secretkey="6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe"
                      render="explicit"
                      onloadCallback={callback}
                    /> */}

                  </div>
                )}
              </form>
            </CardText>
          </Grid>
        </section>
      </div>
    </Wrapper>
  );
};

export default Contact;
