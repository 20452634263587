/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import Plx from 'react-plx';
import { Grid, Wrapper, CardText } from '../../components';
import styles from './MyPractice.module.scss';
import Flowa1 from '../../assets/svg/flowa8.svg';
import Flowa2 from '../../assets/svg/groupofflowa3.svg';
import Flowa3 from '../../assets/svg/flowa4.svg';
import Flowa5 from '../../assets/svg/flowa5.svg';
import Flowa6 from '../../assets/svg/flowa1.svg';
import Flowa7 from '../../assets/svg/flowa6.svg';
import Title from '../../assets/svg/title4.svg';

const MyPractice = () => (
  <Wrapper>
    <div id="MyPractice" className={styles.container}>
      <section className={styles.section}>
        <Grid classes={[styles.gridContainer, styles.gridAjust].join(' ')}>
          <div className={styles.titleContainer}>
            <img src={Title} alt="rr" className={styles.Title} />
            <Plx
              className={styles.Flowa7}
              parallaxData={[
                {
                  start: '#MyPractice',
                  duration: 2000,
                  properties: [
                    {
                      startValue: 200,
                      endValue: -300,
                      property: 'translateY',
                    },
                  ],
                },
              ]}
            >
              <img src={Flowa7} alt="rr" />
            </Plx>
            <Plx
              className={styles.Flowa8}
              parallaxData={[
                {
                  start: '#MyPractice',
                  duration: 2000,
                  properties: [
                    {
                      startValue: 100,
                      endValue: -100,
                      property: 'translateY',
                    },
                  ],
                },
              ]}
            >
              <img src={Flowa7} alt="rr" />
            </Plx>
          </div>
          <img src={Flowa2} alt="rr" className={styles.Flowa2} />
          <img src={Flowa3} alt="rr" className={styles.Flowa3} />

          <CardText classes={[styles.cardAjust, styles.cardAjust1].join(' ')}>
            Les séances durent une heure.
            Lors de la première séance, nous prendrons un temps pour faire connaissance
            et échanger sur vos attentes et vos besoins afin que je puisse vous accompagner.
            {' '}
            <br />
            <br />
            {' '}
            Chaque séance est sur mesure et s&apos;adapte à vos ressentis et vos
            besoins.
          </CardText>
          {/* <div className={styles.flowerContainer}> */}
          <Plx
            className={styles.flowerContainer}
            parallaxData={[
              {
                start: '#MyPractice',
                duration: 1000,
                properties: [
                  {
                    startValue: 0,
                    endValue: -30,
                    property: 'translateY',
                  },
                ],
              },
            ]}
          >
            <img src={Flowa1} alt="rr" className={styles.Flowa1} />
          </Plx>
          <Plx
            className={styles.flowerContainer}
            style={{ zIndex: '-1' }}
            parallaxData={[
              {
                start: '#MyPractice',
                duration: 1000,
                properties: [
                  {
                    startValue: 10,
                    endValue: -10,
                    property: 'rotate',
                  },
                ],
              },
            ]}
          >
            <img src={Flowa5} alt="rr" className={styles.Flowa4} />
          </Plx>

          {/* </div> */}

          <CardText classes={[styles.cardAjust, styles.cardAjust2].join(' ')}>
            Les séances débuteront par un temps d&apos;accueil, d&apos;écoute
            et d&apos;échange sur vos ressentis du moment.
            {' '}
            <br />
            <br />
            {' '}
            S&apos;en suivra une pratique guidée par ma voix, composée d&apos;exercices
            de respiration et de mouvements
            corporels doux pour vous libérer de vos tensions; un moment de relaxation
            pour vous apaiser, vous détendre et de visualisations positives qui vous
            permettront de combler votre besoin et de développer vos propres capacités
            qui vous permettront de réaliser vos objectifs.
            Vous écrirez quelques mots sur ce que vous avez pu ressentir
            pendant la séance ou je vous écouterai sans jugement, ni a priori.
            {' '}
            <br />
            <br />
            {' '}
            Après chaque séance, vous recevrez un enregistrement
            qui vous permettra de refaire la pratique et d&apos;intégrer tous ses bienfaits.
            <Plx
              className={styles.Flowa8}
              parallaxData={[
                {
                  start: '#MyPractice',
                  duration: 1000,
                  properties: [
                    {
                      startValue: -100,
                      endValue: 20,
                      property: 'translateY',
                    },
                    {
                      startValue: 30,
                      endValue: -30,
                      property: 'rotate',
                    },
                  ],
                },
              ]}
            >
              <img src={Flowa7} alt="rr" />
            </Plx>
          </CardText>
          <CardText classes={[styles.cardAjust, styles.cardAjustYellow].join(' ')}>
            "Un rituel quotidien est une manière de dire : Je vote pour moi même;
            Je prends soin de moi ."
            <img src={Flowa6} alt="rr" className={styles.Flowa5} />
          </CardText>

          <CardText classes={[styles.cardAjust, styles.cardAjustTarif].join(' ')}>
            <div className={styles['title-container']}>
              <h2>Tarifs</h2>
            </div>
            <div className={styles['seances-container']}>
              <div className={styles.seance}>
                <h4>Séance individuelle pour adulte</h4>
                <p className={styles.prices}>
                  <span>Durée 1h: </span>
                  <span><strong>55 euros</strong></span>
                </p>
              </div>
              <div className={styles.seance}>
                <h4>Séance individuelle pour enfant</h4>
                <p className={styles['info-seance']}>(séance construite sur des supports tels que le jeu et les contes)</p>
                <p className={styles.prices}>
                  <span>Durée 30mn: </span>
                  <span><strong>40 euros</strong></span>
                </p>
              </div>
            </div>
          </CardText>
        </Grid>
      </section>
    </div>
  </Wrapper>
);

export default MyPractice;
