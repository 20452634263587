import React from 'react';
import PropTypes from 'prop-types';
import styles from './AppContainer.module.scss';

const AppContainer = ({ children }) => (
  <div className={styles['app-container']}>
    <div className={styles['app-centred-container']}>
      {children}
    </div>
  </div>
);

AppContainer.defaultProps = {
  children: null,
};

AppContainer.propTypes = {
  children: PropTypes.shape([PropTypes.node]),
};

export default AppContainer;
