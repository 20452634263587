/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Welcome.module.scss';
import SvgWrapper from '../../components/SvgWrapper';
import { ReactComponent as FannyPerezLogo } from '../../assets/svg/logofanny5.svg';

const Welcome = ({ style }) => {
  // const refContainer = React.createRef();
  // const refImg = React.createRef();
  const [addAnimeLeaveWelcome, setAddAnimeLeaveWelcome] = useState(false);

  useEffect(() => {
    // Si le logo central du loader ss'est déplacé onn peut
    // ajouter l'animation pour quitter <Welcome /> en nfondu
    // if (style.transform === 'translate(0%, 0%)') {
    //   setAddAnimeLeaveWelcome(true);
    // }
    setTimeout(() => {
      setAddAnimeLeaveWelcome(true);
    }, 2000);
  }, []);

  return (
    <div className={[styles.container, addAnimeLeaveWelcome && styles.addAnimeLeaveWelcome].join(' ')}>
      <SvgWrapper classes={styles.svgWrapper} svg={<FannyPerezLogo />} />
    </div>
  );
};

Welcome.defaultProps = {
  style: {},
};
Welcome.propTypes = {
  style: PropTypes.shape([PropTypes.number, PropTypes.string]),
};

export default Welcome;
