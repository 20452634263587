import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardText.module.scss';

const CardText = React.memo(({ children, style, classes }) => (
  <div className={[styles['card-container'], classes].join(' ')} style={{ ...style }}>
    {children}
  </div>
));

CardText.defaultProps = {
  style: { },
  classes: '',
};

CardText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.objectOf(PropTypes.string),
  classes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default CardText;
