import React from 'react';
import PropTypes from 'prop-types';
import styles from './Wrapper.module.scss';

const Wrapper = ({ children }) => (
  <div className={styles['app-container']}>
    <div className={styles['app-centred-container']}>
      {children}
    </div>
  </div>
);

Wrapper.defaultProps = {
  children: null,
};

Wrapper.propTypes = {
  children: PropTypes.shape([PropTypes.node]),
};

export default Wrapper;
