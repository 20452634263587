/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import Burger from '../Burger';
import SvgWrapper from '../SvgWrapper';
import Navigation from '../Navigation';
import { ReactComponent as FannyPerezLogo } from '../../assets/svg/logofanny5.svg';
import Wrapper from '../Wrapper';

const Header = React.memo(({ allRefs, logoHeaderRef }) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isMobil, setIsMobil] = useState(null);
  const [appIsLoading, setAppIsLoading] = useState(true);

  const navRef = useRef(null);

  const resizeShow = () => {
    if (window.innerWidth < 951) {
      setIsMobil(true);
    } else {
      setIsMobil(false);
      setToggleMenu(false);
    }
  };

  const handleAnchor = (event, index) => {
    if (isMobil) {
      setToggleMenu(false);
    }
  };

  const [showBurger, setShowBurger] = useState(false);

  const scroll = () => {
    if (window.scrollY > 149) {
      setShowBurger(true);
    } else {
      setShowBurger(false);
    }
  };

  useEffect(() => {
    resizeShow();
    setTimeout(() => {
      setAppIsLoading(false);
    }, 2000);

    window.addEventListener('resize', resizeShow);
    window.addEventListener('scroll', scroll);

    return () => {
      window.removeEventListener('resize', resizeShow);
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  return (
    <div className={styles.header}>
      <Wrapper>
        <div className={[styles['inner-header'], appIsLoading ? styles['inner-header-start'] : styles['inner-header-onnav']].join(' ')}>
          <div className={[styles['logo-container'], appIsLoading ? styles['logo-container-start'] : styles['logo-container-onnav']].join(' ')}>
            <h1><SvgWrapper logoHeaderRef={logoHeaderRef} svg={<FannyPerezLogo />} /></h1>
          </div>
          <Navigation
            classes={[
              styles['navigation-container'],
              !toggleMenu
                ? styles['navigation-container-close']
                : styles['navigation-container-open'],
              // showBurger && styles['navigation-container-hidden'],
            ].join(' ')}
            navRef={navRef}
            style={{ transition: isMobil ? '1s' : 'none' }}
            handleClick={handleAnchor}
          />
          <Burger
            setToggleMenu={(value) => setToggleMenu(value)}
            toggleMenu={toggleMenu}
            navRef={navRef}
            handleAnchor={handleAnchor}
          />
        </div>
      </Wrapper>
    </div>
  );
});

Header.defaultProps = {
  allRefs: [],
  logoHeaderRef: [],
};

Header.propTypes = {
  logoHeaderRef: PropTypes.shape([PropTypes.node]),
  allRefs: PropTypes.shape([PropTypes.node]),
};
export default Header;
