/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import styles from './Burger.module.scss';

const Burger = React.memo(({
  setToggleMenu,
  toggleMenu,
  navRef,
  handleAnchor,
}) => {
  const [showBurger, setShowBurger] = useState(null);

  const handleScroll = () => {
    if (window.scrollY > 149) {
      setShowBurger(true);
    } else {
      setShowBurger(false);
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={[styles.burgerContainer, showBurger ? styles.isShowingBurger : styles.isHiddenBurger].join(' ')}>
      <div
        className={styles.menuBurger}
        onClick={() => setToggleMenu(!toggleMenu)}
        onKeyDown={() => setToggleMenu(!toggleMenu)}
        role="presentation"
      >
        <div className={[styles.burger, toggleMenu ? styles.firstBurger : ''].join(' ')} />
        <div className={[styles.burger, toggleMenu ? styles.midBurger : ''].join(' ')} />
        <div className={[styles.burger, toggleMenu ? styles.thirdBurger : ''].join(' ')} />
        <div className={[styles.burger, styles.burgerClose, toggleMenu ? styles.burgerOpenLeft : styles.burgerCloseLeft].join(' ')} />
        <div className={[styles.burger, styles.burgerClose, toggleMenu ? styles.burgerOpenRight : styles.burgerCloseRight].join(' ')} />
      </div>
      {showBurger && (
        <div className={styles['scroll-menu']}>
          <Navigation
            navRef={navRef}
            handleClick={handleAnchor}
            style={{
              opacity: toggleMenu ? '1' : '0',
            }}
          />
        </div>
      )}
    </div>
  );
});

Burger.defaultProps = {
  toggleMenu: false,
  navRef: React.createRef(),
  setToggleMenu: () => {},
  handleAnchor: () => {},

};
Burger.propTypes = {
  setToggleMenu: PropTypes.func,
  handleAnchor: PropTypes.func,
  toggleMenu: PropTypes.bool,
  navRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ]),
};

export default Burger;
