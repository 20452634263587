/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Mentions.module.scss';

const conditions = [
  {
    title: 'Hébergeur',
    paragraphe: [
      'OVH',
      'Adresse : 2 rue Kellerman BP - 80157 59053 Roubaix',
      'Tél. : 1007',
    ],
  },
  {
    title: 'Propriété intellectuelle',
    paragraphe: [
      'La structure générale, les textes, images, graphismes, et tout autre élément composant www.fannyperez.fr., hormis les crédits spécifiés à l’article suivant « Autres crédits », sont la propriété exclusive de Mme Perez Fanny : gestionnaire et hébergeur de www.fannyperez.fr. ',
      'L’ensemble de www.fannyperez.fr. relève de la législation française et internationale sur les droits d’auteur et de la propriété intellectuelle. Les noms, marques et enseignes cités sur www.fannyperez.fr. sont la propriété de leurs déposants respectifs.',
      'Toute utilisation ou reproduction, totale ou partielle, de www.fannyperez.fr., des éléments qui le composent et/ou des informations qui y figurent, par quelque procédé que ce soit, sans l’autorisation du titulaire du droit sur ces éléments, sera immédiatement poursuivi, soit dans le cadre d’une action en contrefaçon, soit dans le cadre d’une action en concurrence déloyale.',
    ],
  },
  {
    title: 'Liens hypertextes',
    paragraphe: [
      'Mme Perez Fanny autorise tout site Internet à établir un lien pointant vers l’adresse de la page d’accueil uniquement : www.fannyperez.fr., sous réserve des dispositions légales en vigueur.',
      'Tout lien vers www.fannyperez.fr. doit faire l’objet d’une information préalable au Webmaster de www.fannyperez.fr., Mme Perez Fanny se réservant le droit, à tout moment, sans préavis et sans avoir à motiver sa décision, d’interdire ce lien.',
    ],
  },
  {
    title: 'Dysfonctionnements',
    paragraphe: [
      'www.fannyperez.fr. est conçu pour présenter les meilleurs résultats quel que soit le navigateur utilisé. Mme Perez Fanny ne pourra être tenue pour responsable de tout mauvais fonctionnement et affichage ou dommages causés au système informatique du visiteur, directs ou indirects, résultant de l’utilisation de www.fannyperez.fr.',
    ],
  },
  {
    title: 'Sites liés',
    paragraphe: [
      'Mme Fanny Perez peut vous proposer des liens vers d’autres sites Internet. Vous y accédez librement et vous quitterez alors le champ d’application des présentes conditions générales. La responsabilité de Mme Fanny Perez ne pourra pas être engagée au regard du contenu et du fonctionnement de ces sites tiers.',
    ],
  },
  {
    title: 'Piratage et virus',
    paragraphe: [
      'La responsabilité de Mme Fanny Perez ne pourra jamais être recherchée en cas de dysfonctionnements ou dommages causés au système informatique du visiteur consécutifs au piratage de son site ou virus transportés à son insu ou à l’insu de ses partenaires techniques.',
    ],
  },
  {
    title: 'Responsable de la publication',
    paragraphe: [
      'Le responsable de la publication est @djewai.',
    ],
  },
  {
    title: 'Politique de confidentialité',
    paragraphe: [
      'Pour tout ce qui a trait au respect de vos données personnelles, merci de consulter notre Politique de confidentialité.',
    ],
  },
];

const politique = [
  {
    title: 'I. La collecte des données à caractère personnel',
    paragraphes: [
      'Les données à caractère personnel collectées ou détenues ne sont en aucun cas collectées à des fins de prospection ou communication commerciales. Mme Perez Fanny  s’engage à ne communiquer en aucun cas à des tiers quels qu’ils soient les données à caractère personnel récoltées via le formulaire de contact',
    ],
  },
  {
    title: 'I. 2) Le questionnaire en ligne',
    paragraphes: [
      'Le questionnaire en ligne s’entend du formulaire de demande de contact.',
      'Les informations suivantes sont collectées et traitées : Coordonnées de l’internaute ou de la société (Nom, prénom,, email, demande), adresse IP, numéro de session, informations sur votre système d’exploitation et navigateur internet, et le cas échéant les éléments fournis dans le questionnaire en ligne.',
      'L\'utilisation de ces informations est faite uniquement afin d\'établir un contact avec la personne ayant rempli le questionnaire en ligne et afin de lui proposer un rendez-vous avec une personne habilitée de Mme Perez Fanny pour établir un devis personnalisé sans engagement et en assurer le suivi à l\'issue de la prise de contact. Ce contact sera établi par téléphone et/ou courrier électronique.',
      'Les données collectées à l\'occasion de l\'envoi du questionnaire en ligne complété sont conservées pendant une durée maximale de 3 ans.',
      'L\'utilisation de ces informations telle que décrite ci-dessus est autorisée par la réglementation applicable à la protection des données personnelles. Le traitement de ces données à caractère personnel repose dans ce cas sur le consentement de la personne concernée.',
    ],
  },
  {
    title: 'I. 3) A partir de l\'utilisation du site internet',
    paragraphes: [
      'www.fannyperez.fr utilise la technologie des cookies pour améliorer la navigation ainsi que l\'expérience des utilisateurs du site.',
      "Lors de la consultation du site, ces cookies peuvent être enregistrés sur les navigateurs du support utilisé, quel que soit le terminal (ordinateur, tablette, Smartphone). Ils servent notamment lors de l'identification pour des analyses de la fréquentation et de l'utilisation des éléments du site.",
      'www.fannyperez.fr utilise l’outil d’analyse du trafic Web Google Analytics. Google Analytics fonctionne à l’aide d’un code de suivi ajouté aux pages de notre site Web. Google Analytics collecte les catégories suivantes de données personnelles : Identifiants numériques y compris les cookies, Adresses de protocole Internet et identificateurs de dispositif, Identifiants de clients.',
      "Le cookie possède une durée de validité qui varie en fonction du type de cookie et permet la reconnaissance du visiteur lors d'une prochaine utilisation du site internet. En tout état de cause, cette durée n'excède pas 13 mois.",
      "A tout moment, les visiteurs peuvent décider d'accepter ou de refuser l'utilisation des cookies ainsi que modifier leurs préférences.",
      "A cet effet, www.fannyperez.fr fait apparaître sur son site un bandeau d'information préalable invitant le visiteur à accepter les cookies. Tant que le visiteur n'a pas donné son consentement au recueil des cookies, aucun cookie ne pourra être déposé sur son terminal, à l'exception des cookies exemptés du recueil du consentement dans la mesure où ils sont strictement nécessaires à la fourniture d'un service expressément demandé par l’utilisateur.",
    ],
  },
  {
    title: 'I. 4) Dans le cadre des prestations et services fournis',
    paragraphes: [
      "Les informations suivantes sont collectées et traitées : le nom, le prénom, l'adresse éléctronique, la nature de la demande .",
      'Tous les éléments nécessaires à ces finalités sont collectés et traités. Ces informations sont utilisées pour honorer les finalités liées aux prestations et services sollicités.',
      "Ces traitements sont autorisés par la réglementation applicable à la protection des données personnelles, et dans tous les cas reposent sur le fondement juridique du contrat liant les personnes concernées et l’Entreprise ou celui de l'intérêt légitime. ",
      'Sauf disposition contraire, les informations visées ci-dessus sont nécessaires afin de remplir nos obligations contractuelles.',
    ],
  },
  {
    title: 'II. Les droits liés aux données personnelles',
    paragraphes:
      {
        head: 'Toute personne dont les données personnelles ont été collectées et traitées dans le cadre de la mise en oeuvre de la présente politique de confidentialité disposent des droits ci-après mentionnés : ',
        subs: [
          "-  le droit d'accéder à ses données à caractère personnel ; ",
          '-  le droit de corriger toute erreur figurant dans les fichiers ;',
          '-  le droit de faire effacer ses données à caractère personnel, de limiter leur traitement ou de s\'y opposer ;',
          '-  le droit de retirer son consentement ; ',
          "-  le droit de s'opposer à la réception de documents de prospection commerciale à l'avenir ; ",
          '-  dans certaines circonstances, le droit de veiller à ce que ces informations soient transférées à un tiers.',
          "-  le droit de définir des directives générales et particulières définissant la manière dont elle entent que soient exercés, après son décès, les droits mentionnés ci-dessus par courrier électronique à l'adresse suivante [contact@fannyperez.fr], accompagnées d'une copie d'une pièce d'identité signée.",
          'Contact et réclamations',
          'En dehors de l’Entreprise, vos données personnelles ne sont ni cédées, ni vendues à des tiers.',
          'Toute personne dispose d\'une faculté de déposer une réclamation auprès de la CNIL.',
          "Toutes les questions soulevées dans la présente Politique et les demandes d'exercice des droits de la personne concernée sont gérées par le Délégué à la Protection des Données qui peut être contacté de la façon suivante : par courrier électronique à l'adresse suivante [contact@fannyperez.fr].",
          "Toute demande d'exercice des droits d'accès, de rectification, de suppression ou de limitation du traitement doit être accompagnée de la copie d'une pièce d'identité du demandeur.",
        ],
      },
  },
  {
    title: 'III. Statut de la présente politique de confidentialité',
    paragraphes: [
      'La présente politique de confidentialité a été mise à jour le « 10/10/2021 » . Nous nous réservons le droit de la modifier à tout moment afin de fournir une information à jour sur la façon dont nous collectons et traitons les données.',
    ],
  },
];

// eslint-disable-next-line arrow-body-style
const Mentions = ({ toggleMention, setToggleMention }) => {
  return (
    <div className={[styles.container, !toggleMention && styles.hideContainer].join(' ')}>
      <div
        className={styles.closeButtonContainer}
        onClick={setToggleMention}
      >
        <div className={styles.closeButton}>
          <div />
          <div />
        </div>
      </div>
      <div className={styles.mentionsContainer}>
        <div className={styles.mentions}>
          <div className={styles['flex-box']}>
            <h1>CONDITIONS GENERALES D’ACCES ET D’UTILISATION</h1>
            <p>
              Vous êtes actuellement librement connecté
              au site internet www.fannyperez.fr, ci-après dénommé le SITE.
            </p>
            <p>
              Ce SITE vous est proposé par :
              Anachronik studio-@djewai
              Anachronik studio est légalement représentée par Monsieur Jean-Yves JEANY.
            </p>
            <p>
              www.fannyperez.fr a pour objet de présenter les activités de Mme Perez Fanny.
              Il fait l’objet de mises à jour périodiques,
              ceci étant, malgré le soin apporté dans le
              suivi de l’évolution de ses activités, les présentations faites n’ont pas de caractère
              contractuel. Mme Perez Fanny ne sera engagée que par ce qui aura été
              convenu par la signature d’un contrat.
            </p>
            <p>
              L’utilisation de www.fannyperez.fr est soumise aux présentes conditions
              générales d’accès et d’utilisation et relève du respect
              des lois applicables en France.
            </p>
            <p>
              Les conditions générales peuvent être modifiées à tout moment,
              et nous vous invitons à vous y reporter à chaque connexion sur le www.fannyperez.fr.
              Ces conditions générales sont régies, interprétées et appliquées conformément
              au droit français.
              En cas de litige, les tribunaux français seront seuls compétents.
            </p>
          </div>
          {conditions.map((elem) => (
            <div key={elem.title} className={styles['flex-box']}>
              <h3>{elem.title}</h3>
              {elem.paragraphe.map((p) => <p key={`${p.substr(1, 18)} ${elem.title} ${Math.random()} `}>{p}</p>)}
            </div>
          ))}
          <div className={styles['flex-box']}>
            <h1>POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES</h1>
            <p>Mme Fanny Perez met en oeuvre des traitements de données à caractère personnel.</p>
          </div>

          {politique.map((elem) => (
            <div key={elem.title} className={styles['flex-box']}>
              <h3>{elem.title}</h3>
              {elem.paragraphes instanceof Array ? (
                <div>{elem.paragraphes.map((p) => <p key={`${elem.title} ${Math.random()} ${p.substr(1, 18)}`}>{p}</p>)}</div>
              ) : (
                <div>
                  <p>{elem.paragraphes.head}</p>
                  {elem.paragraphes.subs.map((s) => <p key={`${elem.title} ${Math.random()} ${s.substr(1, 18)}`}>{s}</p>)}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Mentions.defaultProps = {
  toggleMention: false,
  setToggleMention: () => {},
};

Mentions.propTypes = {
  toggleMention: PropTypes.bool,
  setToggleMention: PropTypes.func,

};
export default Mentions;
