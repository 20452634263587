export const menuNav = [
  'A propos',
  'Mon Parcours',
  'La sophrologie',
  'Ma pratique',
  'Me contacter',
];

export const menuNavMain = [
  {
    linkName: 'A propos',
    id: 'app',
  },
  {
    linkName: 'Mon Parcours',
    id: 'myWay',
  },
  {
    linkName: 'La sophrologie',
    id: 'Sophrology',
  },
  {
    linkName: 'Ma pratique',
    id: 'MyPractice',
  },
  {
    linkName: 'Me contacter',
    id: 'contact',
  },
];

export const boodu = 3;
