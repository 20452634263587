/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import './App.css';
import React, {
  useState,
  // useEffect,
  useRef,
  // Suspense,
  // lazy,
} from 'react';
import HttpsRedirect from 'react-https-redirect';
import {
  Header,
  // Loader,
} from './components';
import {
  Welcome,
  About,
  MyWay,
  MyPractice,
  Sophrology,
  Contact,
  Mentions,
} from './pages';

// import Logo from './assets/svg/logofanny5.svg';

import './assets/sass/main.scss';

// const About = lazy(() => import('./pages/About/index'));
// const MyWay = lazy(() => import('./pages/MyWay/index'));
// const Sophrology = lazy(() => import('./pages/Sophrology/index'));
// const MyPractice = lazy(() => import('./pages/MyPractice/index'));
// const Contact = lazy(() => import('./pages/Contact/index'));

function App() {
  const allRefs = useRef(null);
  const logoHeaderRef = useRef(null);
  const logoWelcome = useRef(null);
  const [toggleMention, setToggleMention] = useState(false);

  return (
    <div id="app" className="App" style={{ overflow: 'hidden' }}>
      <HttpsRedirect>
        <Welcome />
        <Header
          allRefs={allRefs}
          logoHeaderRef={logoHeaderRef}
          logoWelcome={logoWelcome}
        />
        <div ref={allRefs} className="test-container" style={{ display: toggleMention ? 'none' : 'unset' }}>
          <About />
          <MyWay />
          <Sophrology />
          <MyPractice />
          <Contact />
        </div>
        <div id="footer">
          <div className="footer" onClick={() => setToggleMention(true)}>mentions légales</div>
        </div>
        <Mentions toggleMention={toggleMention} setToggleMention={() => setToggleMention(false)} />
      </HttpsRedirect>
    </div>
  );
}

export default App;
